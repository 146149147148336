<template>
  <el-main>
    <h2 class="title">我的订单</h2>
    <el-table
      :data="tableData"
      border
      style="width: 100%; margin-top:50px;"
      class="myprder-box"
    >
      <el-table-column prop="licence_name" label="课程名称" width="180">
      </el-table-column>
      <el-table-column prop="info" label="课程简介" width="280">
        汽车驾驶证学习网络培训课程，符合《机动车驾驶培训教学与考试大纲》和《机动车驾驶培训网络
      </el-table-column>
      <el-table-column prop="periodValidity" label="有效期">
        自购买之日起3年内有效
      </el-table-column>
      <el-table-column prop="total_fee" label="购买价格" width="90">
        <template slot-scope="scope">
          {{ scope.row.total_fee / 100 }}
        </template>
      </el-table-column>
      <el-table-column prop="out_trade_no" label="订单号"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <div v-if="scope.row.status === 0">
            <span style="color:#ebbd29">未支付</span>
          </div>
          <div v-else-if="scope.row.status === 2">
            <span style="color:#f00">已关闭</span>
          </div>
          <div v-else-if="scope.row.status === 6">
            <span style="color:#009241">已支付</span>
          </div>
        </template>
      </el-table-column>
      <!--      <el-table-column label="操作" width="140">-->
      <!--        <template slot-scope="scope">-->
      <!--          <div v-if="scope.row.status === 0" style="text-align: center;">-->
      <!--            <el-button size="medium" @click="delOrder(scope.$index, scope.row)"-->
      <!--              >取消订单-->
      <!--            </el-button>-->
      <!--            <el-button-->
      <!--              size="medium"-->
      <!--              type="primary"-->
      <!--              @click="payMent(scope.$index, scope.row)"-->
      <!--              style="width:98px;margin-left:0;margin-top:10px"-->
      <!--              >待支付-->
      <!--            </el-button>-->
      <!--          </div>-->
      <!--          <div v-else-if="scope.row.status === 2" style="text-align: center;">-->
      <!--            <span style="color:#f00">已关闭</span>-->
      <!--          </div>-->
      <!--          <div v-else-if="scope.row.status === 6" style="text-align: center;">-->
      <!--            <span>已完成</span>-->
      <!--            <el-button-->
      <!--              size="medium"-->
      <!--              type="success"-->
      <!--              @click="handleDelete(scope.$index, scope.row)"-->
      <!--              >电子发票-->
      <!--            </el-button>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <el-dialog
      title="购买课程"
      :visible.sync="Buydialog"
      width="50%"
      center
      :before-close="courseClose"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="确认订单信息"></el-step>
        <el-step title="付款"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <div class="step-item">
        <el-tabs v-model="activeName">
          <el-tab-pane name="0"> </el-tab-pane>
          <el-tab-pane name="1">
            <div class="order-two-content">
              <div class="order-two-left">
                <h2>订单信息</h2>
                <ul>
                  <li>
                    课程名称：<span id="subject">{{
                      this.orderinfo.licence_name
                    }}</span>
                  </li>
                  <li>
                    交易金额：<span id="total_fee">{{
                      this.orderinfo.total_fee / 100
                    }}</span
                    >元
                  </li>
                  <li>购买日期：{{ this.orderinfo.create_time }}</li>
                  <li>
                    <!--                    有&nbsp;&nbsp;效&nbsp;期：{{ this.orderinfo.valid_time }}-->
                    有&nbsp;&nbsp;效&nbsp;期：自购买之日起3年内有效
                  </li>
                  <li>
                    订&nbsp;&nbsp;单&nbsp;号：<span id="out_trade_no">
                      {{ this.orderinfo.out_trade_no }}</span
                    >
                  </li>
                </ul>
              </div>
              <div class="order-two-right">
                <h2>付款方式</h2>
                <ul class="choice-ppayment">
                  <li
                    id="alipay"
                    @click="payType(2)"
                    v-if="type === 2"
                    class="active"
                  >
                    <img src="../assets/images/payment_zfb.jpg" alt="" />
                    <img
                      src="../assets/images/payment_icon.png"
                      alt=""
                      class="choice-icon"
                    />
                  </li>
                  <li id="alipay" @click="payType(2)" v-if="type !== 2">
                    <img src="../assets/images/payment_zfb.jpg" alt="" />
                    <img
                      src="../assets/images/payment_icon.png"
                      alt=""
                      class="choice-icon"
                    />
                  </li>
                  <li @click="payType(1)" v-if="type === 1" class="active">
                    <img src="../assets/images/payment_wx.jpg" alt="" />
                    <img
                      src="../assets/images/payment_icon.png"
                      alt=""
                      class="choice-icon"
                    />
                  </li>
                  <li @click="payType(1)" v-if="type !== 1">
                    <img src="../assets/images/payment_wx.jpg" alt="" />
                    <img
                      src="../assets/images/payment_icon.png"
                      alt=""
                      class="choice-icon"
                    />
                  </li>
                </ul>
              </div>
              <div class="confirm-box">
                <a class="con-btn" @click="next">马上付款</a>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="2">
            <div class="change-alt">
              <span v-if="pay_status == 0" class="icon-checkmark1"></span>
              <span v-if="pay_status == 1" class="icon-cancel1"></span>
              <p v-if="pay_status == 0">付款成功！</p>
              <p v-if="pay_status == 1">付款失败！</p>
            </div>
            <div class="confirm-box"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
    <el-dialog
      title="微信支付"
      :visible.sync="wxpaydialog"
      width="40%"
      center
      :before-close="handleClose"
    >
      <div class="agreement">
        支付金额：
        <div style="font-size: 20px;color: #eba760;margin-left: 10px;">
          {{ this.orderinfo.total_fee / 100 }}
        </div>
        <img :src="this.wximage" />
        请微信扫码支付
      </div>
    </el-dialog>
  </el-main>
</template>
<script>
import { getLicenceName, getResult, removeIsPay, setIsPay } from '../utils/auth'
import { orderlist } from '../api/user'
import { confirmorder, delorder } from '../api/order'

export default {
  inject: ['reload'],
  data() {
    return {
      avatar: '',
      real_name: '',
      licence_title: '',
      licence_name: '',
      jiaxiao_name: '',
      percentage: 20,
      customColor: '#f99b08',
      tableData: [],
      active: 1,
      Buydialog: false,
      identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
      identifyCode: '',
      orderinfo: [],
      code: '',
      innerVisible: false,
      type: 2,
      wxpaydialog: false,
      wximage: '',
      htmls: '',
      inter: '',
      checked: true,
      pay_status: 1,
      start: 120,
      end: 3
    }
  },
  components: {},
  created() {
    this.licence_name = getLicenceName()
    this.getInfo()
    this.getorderlist()
  },
  methods: {
    //用户信息
    getInfo() {
      this.avatar = JSON.parse(getResult()).avatar
      this.real_name = JSON.parse(getResult()).real_name
      this.licence_title = JSON.parse(getResult()).licence_title
      this.jiaxiao_name = JSON.parse(getResult()).jiaxiao_name
    },
    //获取订单列表
    getorderlist() {
      orderlist().then(res => {
        if (res.code === 0) {
          this.tableData = res.result
        }
      })
    },
    //取消订单
    delOrder(index, row) {
      this.$confirm('取消之后不能撤销，请确定是否要取消此订单?', '取消订单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delorder({ out_trade_no: row.out_trade_no }).then(res => {
            //console.log(res)
            if (res.code === 0) {
              this.reload()
              this.$message({
                type: 'success',
                message: '订单取消成功！'
              })
            } else {
              this.$message({
                type: 'error',
                message: '订单取消失败！'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //付款方式
    payType(type) {
      this.type = type
    },
    //待支付
    payMent(index, row) {
      this.orderinfo = row
      this.active = 1
      this.Buydialog = true
    },
    courseClose() {
      clearInterval(this.selTimer)
      this.active = 0
      this.code = ''
      this.Buydialog = false
    },
    //下一步
    next() {
      if (this.active === 1) {
        let params = {
          out_trade_no: this.orderinfo.out_trade_no,
          paytype: this.type,
          mid: this.type,
          platid: 2
        }
        confirmorder(params).then(res => {
          if (res.code === 0) {
            this.selOrder()
            if (this.type === 1) {
              this.wxpaydialog = true
              this.wximage = res.result
            }
            if (this.type === 2) {
              let routerData = this.$router.resolve({
                path: '/applyText',
                query: {
                  htmls: res.result
                }
              })
              this.htmls = res.result
              //打开新页面
              window.open(routerData.href, '_ blank')
            }
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else if (this.active === 2) {
        this.active = 0
      }
    },
    //查询订单
    selOrder() {
      this.selTimer = setInterval(() => {
        if (this.start <= this.end) {
          clearInterval(this.selTimer)
          this.$message({
            message: '支付超时！',
            type: 'error',
            onClose: () => {
              this.wxpaydialog = false
            }
          })
        }
        let params = {
          out_trade_no: this.orderinfo.out_trade_no,
          platid: 2
        }
        selectorder(params).then(res => {
          this.start = this.start - this.end
          if (res.code == 0) {
            this.pay_status = 0
            this.wxpaydialog = false
            this.active = 2
            clearInterval(this.selTimer)
            if (this.type === 1) {
              this.reload()
              this.$router.push({ path: '/catalogs' })
            }
            removeIsPay()
            setIsPay('2')
          } else if (res.code == 1) {
            this.pay_status = 1
          } else {
            clearInterval(this.selTimer)
            this.$message({
              message: '系统错误！',
              type: 'error'
            })
          }
        })
      }, this.end * 1000)
    },
    handleClose(done) {
      this.active = 2
      clearInterval(this.selTimer)
      this.wxpaydialog = false
    }
  },
  computed: {
    activeName: {
      get() {
        return this.active.toString()
      },
      set(newValue) {
        return newValue
      }
    }
  }
}
</script>
